<template>
	<div class="cbox">
		<div class="headbar">
			<div style="display: flex;">

				<el-input v-model="keyword" placeholder="标题关键字" style="max-width: 200px; margin-right: 10px;" />
				<el-select @change="searchData" v-model="filterDept" style="width: 300px; margin-right: 10px;" clearable
					filterable>
					<el-option v-for="(item,i) in zhuanyeList" :key="i" :label="item" :value="item"></el-option>

				</el-select>
				<el-button type="primary" plain size="small" icon="el-icon-search" @click="onSearch">搜索</el-button>


				<el-button type="danger" v-if="isAdmin" :disabled="checkList.length==0" plain size="small"
					icon="el-icon-delete" @click="deleteRows">删除</el-button>

				<!-- <el-button type="primary" size="small" icon="el-icon-plus" @click="add">添加分数</el-button> -->


				<el-button v-if="isAdmin" style="margin-left: 10px;" type="text" icon="el-icon-download">
					<a href="/xls/录取学生导入模板.xlsx" style="text-decoration: none;color:inherit">下载录取学生导入模板</a>
				</el-button>

				<el-upload v-if="isAdmin" style="display: inline-block;" action :multiple="true"
					:http-request="uploadTemp" :on-success="handleXlsUpload" :show-file-list="false" name="image">
					<el-button id="btnimport1" style="margin-left: 10px; "
						icon="el-icon-upload">导入录取学生（重复学生会自动覆盖更新）</el-button>
				</el-upload>

				<el-button v-if="isAdmin" id="btnimport2" :type="isOpen==1?'success':'warning'"
					style="margin-left: 30px; " @click="setOpen"
					icon="el-icon-upload">{{isOpen==1?'已开放查询':'未开放查询'}}</el-button>


				<el-button  id="btnimport5" type="warning" style="margin-left: 30px; " @click="downXls"
					icon="el-icon-download">导出</el-button>


			</div>

		</div>
		<div class="bcontent">


			<el-table :data="DataList" stripe border @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55" />
				<el-table-column type="index" label="编号" width="80">
				</el-table-column>
				<el-table-column prop="ksh" label="考生号" min-width="100">
				</el-table-column>
				<el-table-column prop="stu_name" label="学生姓名" min-width="100">
				</el-table-column>
				<el-table-column prop="zhuanye" label="录取专业" min-width="100">
				</el-table-column>
				<el-table-column prop="idcard" label="身份证" min-width="100">
				</el-table-column>
				<el-table-column prop="ems" label="EMS" min-width="100">
				</el-table-column>

				<el-table-column prop="cdate" label="导入时间" min-width="100">
				</el-table-column>



			</el-table>
		</div>
		<div style="padding-top: 10px;display: flex; flex-direction: row-reverse;">

			<el-pagination background layout="sizes,total, prev, pager, next" :total="page.count"
				:current-page="page.current_page" :page-size="page.per_page" :page-sizes="[20, 50, 100, 500]"
				@current-change="paginate" @size-change="handleSizeChange">
			</el-pagination>

		</div>




		<el-dialog title="分数详情" :visible.sync="dialogVisible" :close-on-click-modal="false" width="800px">
			<el-form ref="form" :model="EditItem" label-width="80px">

				<el-row>
					<el-col :span="12">
						<el-form-item label="类型">

							<el-input v-model="EditItem.lqtype"></el-input>

						</el-form-item>

					</el-col>

					<el-col :span="12">
						<el-form-item label="年份">

							<el-input v-model="EditItem.lqyear"></el-input>

						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="省份">

							<el-input v-model="EditItem.province"></el-input>

						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="科类">

							<el-input v-model="EditItem.kelei"></el-input>

						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="批次">

							<el-input v-model="EditItem.pici"></el-input>

						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="专业名称">

							<el-input v-model="EditItem.zhuanye"></el-input>

						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="省控线">

							<el-input v-model="EditItem.shengkongxian"></el-input>

						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="最高分">

							<el-input v-model="EditItem.score_max"></el-input>

						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="最低分">

							<el-input v-model="EditItem.score_min"></el-input>

						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="平均分">

							<el-input v-model="EditItem.score_avg"></el-input>

						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="备注">

							<el-input v-model="EditItem.beizhu"></el-input>

						</el-form-item>
					</el-col>


					<el-col :span="24">
						<div style="text-align: center; padding: 50px;">

							<el-button type="primary" @click="saveForm">保存</el-button>
						</div>
					</el-col>
				</el-row>



			</el-form>
		</el-dialog>





	</div>
</template>

<script>
	const aes = require("../../utils/aes.js").default

	export default {
		components: {

		},
		data() {
			return {
				dialogVisible: false,
				filterDept: "",
				isAdmin: false,
				EditItem: {
					id: 0,
					lqtype: "",
					lqyear: "",
					province: "",
					kelei: "",
					pici: "",
					zhuanye: "",
					shengkongxian: "",
					score_max: "",
					score_min: "",
					score_avg: "",
					beizhu: ""
				},
				DataList: [],
				zhuanyeList: [],
				keyword: "",
				page: {
					current_page: 1,
					next_page: 1,
					per_page: 20,
					total_page: 1,
					count: 0
				},
				isOpen: 0,
				userinfo: {
					deptName: ""
				},
				checkList: []

			};
		},

		mounted() {
			if (sessionStorage.getItem("username") == "admin") {
				this.isAdmin = true
			}
			this.getTeacherInfo()

		},
		methods: {
			searchData() {
				this.getList(1)
			},
			getZhuanye() {
				this.$http.post("/api/zhuanye_select", {

				}).then(
					res => {
						this.zhuanyeList = res.data.map(e => {
							return e.zhuanye
						});


					})
			},
			getTeacherInfo() {
				if (sessionStorage.getItem("username") != "admin") {
					this.$http.post("/api/user_info", {
						loginName: sessionStorage.getItem("username")
					}).then(res => {
						this.userinfo = res.data
						this.getList();
						this.checkOpen()
						if (!this.userinfo.deptName) {
							this.getZhuanye()
						} else {
							this.zhuanyeList = this.userinfo.deptName.split("|")
						}
					})
				} else {
					this.getZhuanye()
					this.getList();
					this.checkOpen()
				}

			},
			setOpen() {

				this.$http.post("/api/score_setopen", {
					isopen: this.isOpen ? 0 : 1
				}).then(res => {
					this.checkOpen()
				})
			},
			checkOpen() {
				this.$http.post("/api/score_checkopen", {}).then(res => {
					this.isOpen = res.data.isopen
				})
			},
			onSearch() {
				this.getList();
			},
			getList(page) {
				this.$http.post("/api/luqu_list", {
					keyword: this.keyword,
					page: this.page.current_page,
					pagesize: this.page.per_page,
					deptName: this.userinfo.deptName,
					filterDept: this.filterDept
				}).then(
					res => {
						for (let item of res.data.data) {
							if (item.idcard) {
								item.idcard = aes.decrypt(item.idcard)
								let mid = item.idcard.substring(6, 14)
								item.idcard = item.idcard.replace(mid, "*******")
							}

						}
						this.DataList = res.data.data;
						this.page = res.data.page

					})
			},

			paginate(val) {
				this.page.current_page = val
				this.getList()
			},
			handleSizeChange(val) {
				this.page.per_page = val
				this.getList()
			},

			add() {
				this.EditItem = {
						id: 0,
						lqtype: "",
						lqyear: "",
						province: "",
						kelei: "",
						pici: "",
						zhuanye: "",
						shengkongxian: "",
						score_max: "",
						score_min: "",
						score_avg: "",
						beizhu: ""
					},
					this.dialogVisible = true
			},
			updateDetail(e) {
				this.EditItem = e
				this.dialogVisible = true
			},
			handleXlsUpload(e) {

				this.$http.post("/api/import_luqu_xls", {
					url: e.src
				}).then(res => {
					this.$message({
						type: 'success',
						message: '导入成功'
					});

					this.getList();
				})
			},

			deleteRows(id) {
				this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post("/api/delete_luqu", {
						ids: this.checkList.map(e => {
							return e.id
						}).join(",")
					}).then(res => {
						this.$message({
							type: 'success',
							message: '删除成功'
						});

						this.getList(this.page.current_page);
					})

				}).catch(() => {

				});
			},
			saveForm() {
				this.$http.post("/api/score_update", this.EditItem).then(res => {
					this.$message({
						type: 'success',
						message: '保存成功'
					});
					this.dialogVisible = false

					this.getList();
				})
			},
			handleSelectionChange(rows) {

				this.checkList = rows
			},
			downXls() {
				let xls = require("./luquExport.js")
				this.$http.post("/api/luqu_list", {
					keyword: this.keyword,
					page: 1,
					pagesize: 5000,
					deptName: this.userinfo.deptName,
					filterDept: this.filterDept
				}).then(
					res => {
						for (let item of res.data.data) {
							if (item.idcard) {
								item.idcard = aes.decrypt(item.idcard)

							}

						}
						res.data.data
						xls.createXlsx(res.data.data, "录取学生数据")
					})



			}






		}
	}
</script>