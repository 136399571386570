
import * as XLSX from 'xlsx';

export function createXlsx(datalist, name) {

    const header = ["序号", "考生号","学生姓名", "录取专业", "身份证", "EMS", "导入时间"];

    let data = [
        header

    ];
    let i = 0;
    for (let item of datalist) {
       
        
        i++
     
        let row = [i,item.ksh, item.stu_name,item.zhuanye,item.idcard,item.ems,item.cdate
        ]

        data.push(row)
    }

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    // 导出文件
    XLSX.writeFile(workbook, `${name}.xlsx`);

}

